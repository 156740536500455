import React, {useState, useEffect, useContext} from "react";
import './Header.scss';
import logo from '../../assets/img/logo-white.png';
import { Nav, Navbar, NavbarBrand, Button, NavItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import HttpHandlerServices from '../../services/httpService';
import { ToastContainer, toast } from 'react-toastify';
import language from './language.json';
import { contextApp } from "../App/contextApp";

const Header = (props) =>{
    const [userData, setUserData] = useState({
        id: null,
        name: null,
        email: null,
        role: null   
    });
    const [collapsed, setCollapsed] = useState(false)
    const [collapsedUser, setCollapsedUser] = useState(null)
    const [counter, setCounter] = useState(0);
    const { currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])

    useEffect(()=>{ getUserData(); getCounter(); }, []);

    const logOut = () => {
        const endpoint = '/logout';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoint).then(
            async res => {
                const data = await res;
                if(data) {
                    toast.warning(`Successfully logged out`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                    localStorage.removeItem('userSession');
                }
            }
        );
    }

    const getUserData = () => {
        const endpoint = '/user';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoint).then(
            async res => {
                const data = await res;
                setUserData(data);
            },
            error => {
                toast.error(`ERROR AL RECUPERAR DATOS DEL USUARIO, Cierre la session y vuelva a loguearse. Si el error persiste por favor por farvor contacte a soporte help@littlecaesars.com`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
                });
            }
        );
    }

    const getCounter = () =>{
        const endpoint = '/showconversationscounter';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoint).then(
            async res => {
                const data = await res;
                setCounter(data.total)
            }
        );
    }

    return (
        <>
            <ToastContainer />
            <Navbar color="primary" className="p-2 fixed-top">
                <Button  className="mr-2 hamburguer" color="transparent" onClick={() => setCollapsed(!collapsed)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </Button>
                <NavbarBrand href="/" className="mr-auto">
                    <img src={logo} alt="Little Caesars" className="logo"/>
                </NavbarBrand >
                <Nav className="align-items-center text-right user-profile" onClick={() => setCollapsedUser(!collapsedUser)}>
                    <NavItem>
                        <h4 className="text-white emigre-bold text-uppercase m-0 user-name">{userData.name}</h4>
                        <p className="text-danger m-0 user-description">{userData.email}</p>
                    </NavItem>
                    <NavItem>
                        <i className="icomoon icon-user_service text-white ml-3 user_icon p-1"></i>
                    </NavItem>
                </Nav>
            </Navbar>
            <div className={`${collapsed && 'open'} min-vh-100 bg-dark w-75 text-white emigre-bold sidebar`}>
                <ul className="text-uppercase mt-9 p-2 menu-options">
                    
                    <Link className="text-white" to={`/home`} onClick={() => setCollapsed(!collapsed)}>
                        <li className="" title="Dashboard">
                            <i className={`${collapsed && 'open'} icomoon icon-speed mr-2 `}></i>
                            {
                                collapsed ? 'Dashboard' : null
                            }
                            
                        </li>
                    </Link>
                    
                    <Link className="text-white" to={`/atencion`} onClick={() => setCollapsed(!collapsed)}>
                        <li className="" title={texts.customerSupport}>
                                <i className="icomoon icon-atc mr-2"></i> 

                                {
                                    collapsed ? texts.customerSupport : null
                                }
                                
                                {counter > 0 && <Badge color="primary" className="text-white ml-2 badge_counter">{counter}</Badge> }
                        </li>
                    </Link>
                    {
                         userData.role === 1 || userData.role === 2 ? 
                        
                        
                        <Link className="text-white" to={`/users`} onClick={() => setCollapsed(!collapsed)}>
                            <li className="" title={texts.manageUsers}>
                                <i className="icomoon icon-USUARIOS mr-2"></i> 
                                {
                                    collapsed ? texts.manageUsers : null
                                }
                            </li>
                        </Link> 
                        
                        : 
                        
                        null
                    }

                    

                  
                   { userData.role === 1 || userData.role === 2 ? 
                   
                    <Link className="text-white" to={`/stores`} onClick={() => setCollapsed(!collapsed)}>
                        <li className="" title={texts.manageBranches}>
                            <i className="icomoon icon-SUCURSALES mr-2"></i> 
                            {
                                collapsed ? texts.manageBranches : null
                            }
                            
                        </li>
                    </Link> : null
                   }

                   

                    <Link className="text-white" to={`/`} onClick={() => {
                            setCollapsed(!collapsed);
                            logOut();
                        }
                        }>
                        <li className="" title={texts.closeSession}>
                            <i className="icomoon icon-CERRARSESION mr-2"></i>
                            {
                                collapsed ? texts.closeSession : null
                            }
                            
                        </li>
                    </Link>
                </ul>
            </div>
                
            {/* menu usuario */}
            <div className={`${collapsedUser} user-menu bg-dark text-white emigre-bold sidebar`}>
                <ul className="text-uppercase p-2">
                   <Link className="text-white" to={`/new-password`} onClick={() => {
                            setCollapsed(!collapsed);
                            
                        }
                        }>
                        <li className="lil_menu">
                            <i className="icomoon icon-user_service mr-2"></i>{texts.changePassword}
                        </li>
                    </Link>
                    <Link className="text-white" to={`/`} onClick={() => {
                            setCollapsed(!collapsed);
                            logOut();
                        }
                        }>
                        <li className="lil_menu">
                            <i className="icomoon icon-CERRARSESION mr-2"></i>{texts.closeSession}
                        </li>
                    </Link>
                </ul>
            </div>

        </>

    )

}

export default Header