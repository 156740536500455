import React, { useState, useEffect, useCallback} from "react"
import Layout from '../../layout/layout';
import Select from 'react-select';
import AddNewUser from './AddNewUser/AddNewUser';
import DetailsUser from './DetailsUser/DetailsUser';
import { Container, Row, Col, Button, Spinner, Card, Modal, ModalBody } from 'reactstrap';
import MaterialTable from 'material-table';
import HttpHandlerServices from '../../../services/httpService';
import './Users.scss';
import { ToastContainer, toast } from 'react-toastify';




const Users = (props) => {

   const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? '#ff7534'
            : null,
          color: isDisabled
            ? 'white'
            : isSelected,
          cursor: isDisabled ? 'not-allowed' : 'white',
    
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? 'white' : '#ff7534'),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor:'#ff7534',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'white',
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: 'white',
        ':hover': {
          backgroundColor: data.color,
          color: '#dc3545',
        },
      }),
   };

   const [userData, setUserData] = useState({
      id: null,
      name: null,
      email: null,
      role: null   
  });

   const getUserData = () => {
      const endpoint = '/user';
      const httpHandlerServices = new HttpHandlerServices();
      httpHandlerServices.getMethod(endpoint).then(
         async res => {
            const data = await res;
            setUserData(data);
         },
         error => {
            toast.error(`ERROR AL RECUPERAR DATOS DEL USUARIO, Cierre la session y vuelva a loguearse. Si el error persiste por favor por farvor contacte a soporte help@littlecaesars.com`
            , {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 8000
            });
         }
      );
   }

   const getFranchise = useCallback(() => {

      const httpHandlerServices = new HttpHandlerServices();

      const endpoint = '/showfranchise';

      httpHandlerServices.getMethod(endpoint).then(

         async (res) => {
            
            const data = await res;

            const options = [];

            if(data) {

               data.forEach(ele => {

                  const item = {

                     value: ele.id,

                     label: ele.franchise

                  }

                  options.push(item);

               });

               setFranchises(options);
            }


         },

         async (error) => {

            const data = await error;

            if (data) {
               return;
            }

         }

      )

   },[]);



   const getRoles = useCallback(() => {
      const httpHandlerServices = new HttpHandlerServices();
      const endpoint = '/showroles';
      httpHandlerServices.getMethod(endpoint).then(
         async (res) => {
            const data = await res;
            const options = [];
            data[0].map(ele => {
               const item = {
                  value: ele.id,
                  label: ele.name
               }
               options.push(item);

            });
            setRoles(options);
         }
      )
   },[]);

   const [filterUser, setFilterUser] = useState({
      franchise_ids: 0,
      roles: 0
   });

   const getUserList = () => {
      
      init([]);

      setLoader(true);

      const httpHandlerServices = new HttpHandlerServices();

      const endpoint = '/showusers';      

      httpHandlerServices.postMethod(endpoint, filterUser).then(
         async(res) => {
            const data = await res;
            init(data);
            setLoader(false);
            
         },
         async (error) => {
            const data = await error;
            if(data)
               setLoader(false);
            
         }
      )
          
   };

   const deleteUser = () => {

      const httpHandlerServices = new HttpHandlerServices();

      const endpoint = '/deleteuser';

      const data = {

          user_id: user.id

      }

      httpHandlerServices.postMethod(endpoint, data).then(

          async (res) => {

              const data = await res;

              if (data) {

                 toast.warning(`USUARIO ELIMINADO DE FORMA CORRECTA`, {
   
                     position: toast.POSITION.BOTTOM_RIGHT,
   
                     autoClose: 8000
   
                 });
   
                 toggle();
   
                 getUserList();

               }


              
          },
          async (error) => {

              const data = await error;

              if (data) {
                 
                 toast.warning(`SE PRODUJO UN ERROR`, {
   
                     position: toast.POSITION.BOTTOM_RIGHT,
   
                     autoClose: 8000
   
                 });
                 
              }

          } 
      );
  }
      
  const [rows, setRows] = useState([]);
  
   const init = (data) => {
      setRows(data); 
   }


   
   const columns = [
      {title: <div><i className="icomoon icon-user_service mr-2" /> CLIENTE</div>, 
      field: "name", 
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.name}
      </div>
      }, 
      {field: "franchise", title: <div><i className="icomoon icon-shop mr-2" /> FRANQUICIA</div>, 
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.franchise}
      </div>,
      render: cellData => <div style={{
         textAlign: 'center',
         }}>
         { cellData.franchise}
      </div>
      },
      {field: "email", title: <div><i className="icomoon icon-mail mr-2" /> CORREO</div>,
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.email}
      </div>
      },
      {field: "role", title: <div><i className="icomoon icon-user_service mr-2" /> ROL</div>, 
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.role}
      </div>
      },
      {field: "created_at", title: <div><i className="icomoon icon-date mr-2" /> FECHA</div>, 
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.created_at}
      </div>
      },
      {field: "created_by", title: <div><i className="icomoon icon-user_service mr-2" /> AGREGADO</div>,
      render: rowData => <div style={{
         textAlign: 'center',
         }}>
         { rowData.created_by}
      </div>
      },
   ];
      

   const [show, setShow] = useState();  

   const [loader, setLoader] = useState(false);

   const [franchises, setFranchises] = useState([]);

   const [roles, setRoles] = useState([]);

   const {className} = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [user, setUser] = useState([]);


   useEffect(()=>{
      getUserData();

      getFranchise();

      getRoles();

      getUserList();
      
   }, [filterUser]);
   
   return(
      <Layout>
         <ToastContainer />
         <div className="overview componentUsers">
            { loader ? <Spinner className='spinner' style={{ width: '6rem', height: '6rem' }} color="secondary" /> : null}
            <>
               {!show && <Container>
                  <Row>
                     <Col xs={12} className="button-new-user text-right" >
                        <Button color="dark" className="text-uppercase emigre-bold button-new-user button-add" onClick={() => setShow(!show)} disabled={userData.role !== 1 ?  true : false}>
                           Agregar nuevo usuario <i className="icomoon icon-user_service ml-2"></i></Button>
                     </Col>
                  </Row>
                  <br />
               </Container>}
            </>   
            {!show && <Container>
               <Card body tag="article" className="d-block z-indez-99">
                  <Row xs={12}>
                     <Col xs={12} md={6} className="mb-2">
                        <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder='FILTRAR POR FRANQUICIA'
                              name="franchise" 
                              id="franchise"
                              closeMenuOnSelect={true}
                              isMulti
                              options={franchises}
                              noOptionsMessage={() => 'Ya no hay más opciones'}
                              styles={colourStyles}
                              onChange={(e)=>{
                                 const array = [];
                              
                                 if(e){
                                    if (e.length > 0 ) {
                                       e.map(ele => {
                                          array.push(ele.value);
                                       });
                                    }
                                 }
                                 const string =  array.length > 0 ? array.toString() : '0';
                           
                                 setFilterUser({...filterUser, franchise_ids: string})
                           
                              }}
                              theme={theme => ({
                                 ...theme,
                                 colors: {
                                    ...theme.colors,
                                    primary25: '#ff7534',
                                    primary: '#ff7534',
                                    neutral90: 'white',
                                    primary50:"#ff7534",
                                 },
                                 })}
                              
                              />
                     </Col>
                     <Col xs={12} md={6} className="mb-2">
                        <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder='FILTRAR POR TIPO DE USUARIO'
                              name="role" 
                              id="role"
                              closeMenuOnSelect={true}
                              isMulti
                              options={roles}
                              noOptionsMessage={() => 'Ya no hay más opciones'}
                              styles={colourStyles}
                              onChange={(e)=>{
                                 const array = [];
                                 if(e){
                                    if (e.length > 0 ) {
                                       e.map(ele => {
                                          array.push(ele.value);
                                       });
                                    }
                                 }
                                 const string =  array.length > 0 ? array.toString() : '0';
                                 setFilterUser({...filterUser, roles: string})
                                
                           
                              }}
                              theme={theme => ({
                                 ...theme,
                                 colors: {
                                    ...theme.colors,
                                    primary25: '#ff7534',
                                    primary: '#ff7534',
                                    neutral90: 'white',
                                    primary50:"#ff7534",
                                 },
                                 })}
                              />
                     </Col>
                  </Row>
               </Card>
            </Container>}
            <br />
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <h4 className="text-uppercase emigre-bold text-dark text-center">
                        ¿ESTÁS SEGURO DE QUERER DESHABILITAR <br /> EL USUARIO?
                    </h4>    
                    <p className="text-center">Selecciona una opción antes de continuar.</p>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={
                            ()=> {
                              deleteUser()
                            }
                        } className="text-uppercase text-white emigre-bold mr-4">ELIMINAR</Button>
                        <Button color="dark" onClick={
                            ()=> {
                                toggle();
                            }
                        } className="text-uppercase text-white emigre-bold">CANCELAR</Button>
                    </div>
                </ModalBody>
            </Modal>

            <>
               {!show && <Container className="customers table-users">
                  <MaterialTable 
                  title=""
                  columns={columns} 
                  data={rows} 
                  localization={{
                     body: {
                        emptyDataSourceMessage: 'NO HAY DATOS QUE MOSTRAR',
                     },
                     toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                     },
                     pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: ' {from}-{to} de {count}',
                        firstTooltip: 'Principio',
                        previousTooltip: 'Anterior',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Final'
                     }
                  }}

                  detailPanel={[
                     {
                         icon: 'arrow_forward_ios_Icon',
                         tooltip: 'Detalle',
                         render: rowData => {
                             return (
                             <div
                                 style={{  }}
                             >
                              <DetailsUser data={rowData} refresh={() => getUserList()}/>
                             </div>
                             )
                         },
                     }
                     
                  ]}
                  actions={[
                     rowData => ({
                         icon: 'delete',
                         tooltip: 'Eliminar Sucursal',
                         onClick: (event, rowData) => {
                            if(event) {
                               setUser(rowData)
                               toggle();
                            }
                         },
                     })
                     ]}
                    
                  options={{
                     headerStyle: {
                        backgroundColor: '#424242',
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Emigre Bold',
                        margin: '5px'
                     }}}

                  /**/
            
                  />
               </Container>}
            </>
            {show && <AddNewUser/>}
   
         </div>
      </Layout>
   );
}

export default Users;