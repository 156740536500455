import React, { Fragment, useState, useEffect } from 'react';
import '../Users.scss';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody } from 'reactstrap'
import HttpHandlerServices from '../../../../services/httpService';
import pin from '../../../../assets/img/pin.png';
import { ToastContainer, toast } from 'react-toastify';

const MarkComponent = ({ text }) => 
<div className='pinContainer'>
    <img src={pin} alt='' className='pin'/>
</div>;

const DetailsUser = (props) => {
    const [notEditable, setNotEditable] = useState(true);
    
    const [dataStore, setDataStore] = useState({
        'address': '',
        'created_at': '',
        'deleted_at': null,
        'franchise_id': null,
        'id': 0,
        'lat': props.data.lat,
        'lng': props.data.lng,
        'locality_id': null,
        'name': '',
        'number': '',
        'state_id': null,
        'street': '',
        'sublocality': '',
        'tableData': {},
        'updated_at': '',
        'zipcode': ''
    });
    
    const [location, setLocation] = useState({
        center: {
            lat: 19.89,
            lng: -99.33
        },
        pin: {
            lat: dataStore.lat,
            lng: dataStore.lng
        },
        zoom: 5
    });

    const [validate, setValidate] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    useEffect(()=>{
        setDataStore(props.data);
    }, []);
    
    const getData = (e) =>{
        setDataStore({...dataStore, [e.target.name]: e.target.value})
        setValidate({ ...validate, [e.target.name]: false });
    }

    

    const changeLocation = (e) => {
        
        if(notEditable) {
            return;
        } 
        setLocation(
            {
                ...location, 
                    
                    pin: {
                        lat: e.lat,
                        lng: e.lng
                    },
            }
        );

        //const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.lat},${e.lng}&key=AIzaSyD1OBKldkqjnDDgsjjyanYIGmALQSCtnzQ`;

    }

    const editStore = () => {
        if(notEditable) {
            setNotEditable(false);
        } else {
            const httpHandlerServices = new HttpHandlerServices();
            const endpoint = '/edituser';
            const data = {
                user_id: dataStore.id,
                name: dataStore.name,
                email: dataStore.email 
            }
       
            httpHandlerServices.postMethod(endpoint, data).then(
                async (res) => {

                    const data = await res;

                    if(data) {

                        toast.warning(`USUARIO ATUALIZADO DE FORMA CORRECTA`, {
    
                            position: toast.POSITION.BOTTOM_RIGHT,
    
                            autoClose: 8000
    
                        });

                    }


                    props.refresh();
                    
                },
                async (error) => {

                    const data = await error;

                    if (data) {

                        toast.warning(`SE PRODUJO UN ERROR, VERIFIQUE SE CONEXIÓN E INTENTE NUEVAMENTE SI EL PROBLEMA PERSISTE CONTACTE A SOPORTE`, {
    
                            position: toast.POSITION.BOTTOM_RIGHT,
    
                            autoClose: 8000
    
                        });
                    }


                }

            )

            setNotEditable(true);
        }
    }

    const deleteUser = () => {

        const httpHandlerServices = new HttpHandlerServices();

        const endpoint = '/deleteuser';

        const data = {

            user_id: dataStore.id

        }

        httpHandlerServices.postMethod(endpoint, data).then(

            async (res) => {

                const data = await res;

                toast.warning(`USUARIO ELIMINADO DE FORMA CORRECTA`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });

                toggle();

                props.refresh();
            },
            async (error) => {

                const data = await error;

                toast.warning(`SE PRODUJO UN ERROR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            } 
        );
    }

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const MarkComponent = ({ text }) => 
        <div className='pinContainer'>
            <img src={pin} alt='' className='pin'/>
        </div>;

    return(
        <Fragment>
            <Container style={{paddingTop:20, background:'#e4ebf1'}}>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <h4 className="text-uppercase emigre-bold text-dark text-center">ESTAS APUNTO DE ELIMINAR A {dataStore.name}<br/> ¿Realmente quieres Eliminarlo?</h4>    
                    <p className="text-center">Elige una opción para continuar</p>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={toggle} className="text-uppercase text-white emigre-bold mr-4">No, Cancelar</Button>
                        <Button color="dark" onClick={deleteUser} className="text-uppercase text-white emigre-bold">Sí, Eliminarno</Button>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer />
                <Row>
                <Col md='2' sm='12'></Col>
                   
                    <Col md='8' sm='12' style={{
                        background:'#e9ecef', 
                        paddingTop:20, 
                        padding:30, 
                        borderRadius: 4,
                        boxShadow: '1px 1px 6px 1px #ccc, 1px 1px 6px 1px #ccc'}}>
                        <FormGroup>
                            <Label className="align-left"><i className="icomoon icon-user_service mr-2"></i> NOMBRE DE USUARIO</Label>
                            <Input 
                            type="text" 
                            name="name" 
                            id="name"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                            value={dataStore.name} 
                            onChange={(e) => getData(e)}  disabled={notEditable}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-mail mr-2"></i> CORREO ELECTRÓNICO</Label>
                            <Input 
                            type="text" 
                            name="email" 
                            id="email"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                            value={dataStore.email} 
                            onChange={(e) => getData(e)}  disabled={notEditable}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-user_service mr-2"></i>TIPO DE USUARIO</Label>
                            <Input 
                            type="text" 
                            name="role" 
                            id="role"  
                            value={dataStore.role} 
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)} disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-shop mr-2"></i> FRANQUICIA:</Label>
                            <Input 
                            type="text" 
                            name="franchise" 
                            id="franchise"  
                            value={dataStore.franchise}  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)} disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-globe mr-2"></i> PAÍS:</Label>
                            <Input 
                            type="text" 
                            name="country" 
                            id='country'
                            value={'MEXICO'}    
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)}  disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-date mr-2"></i> FECHA DE INGRESO:</Label>
                            <Input 
                            type="text" 
                            name="created_at" 
                            id="created_at"  
                            value={dataStore.created_at}
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)} disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-user_service mr-2"></i> PERFIL CREADO POR:</Label>
                            <Input 
                            type="text" 
                            name="create_by" 
                            id="reate_by"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            value={dataStore.created_by}
                            onChange={(e) => getData(e)} disabled={true}/>
                        </FormGroup>

                    </Col>
                    <Col md='2' sm='12'></Col>
                   
                    <Col lg='12' xs='12'>
                        <Row className="d-flex justify-content-md-center">
                            <Col xs='4'>
                                <Button 
                                color="primary" 
                                className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" 
                                block
                                onClick={() => editStore()}>
                                    <i className="icomoon icon-gear mr-2"></i>{notEditable ? 'MODIFICAR' : 'GUARDAR'}</Button>
                            </Col>
                            <Col xs='4'>
                                <Button 
                                color="dark" 
                                className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" 
                                block
                                onClick={toggle}
                                ><i className="material-icons mr-2 align-bottom">delete</i>ELIMINAR USUARIO</Button>
                            </Col>
                        </Row>
                        
                    </Col>

                </Row>

            </Container>
            
        </Fragment>
    );

}

export default DetailsUser;