import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody} from 'reactstrap'
import GoogleMapReact from 'google-map-react';
import pin from '../../../../assets/img/pin.png';
import { toast } from 'react-toastify';
import HttpHandlerServices from '../../../../services/httpService';
import Select from 'react-select';
import moment from 'moment';

const EditStore = (props) => {

    const [notEditable, setNotEditable] = useState(true);
    
    const [dataStore, setDataStore] = useState({
        'address': '',
        'created_at': '',
        'deleted_at': null,
        'franchise_id': null,
        'id': null,
        'lat': props.data.lat,
        'lng': props.data.lng,
        'locality_id': null,
        'name': '',
        'number': '',
        'state_id': null,
        'street': '',
        'sublocality': '',
        'updated_at': '',
        'zipcode': ''
    });
    
    const [location, setLocation] = useState({
        center: {
            lat: 19.89,
            lng: -99.33
        },
        pin: {
            lat: dataStore.lat,
            lng: dataStore.lng
        },
        zoom: 5
    });

    const [validate, setValidate] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    useEffect(()=>{
        setDataStore(props.data);
    }, [])
    

    

    const getData = (e) =>{
        setDataStore({...dataStore, [e.target.name]: e.target.value})
        setValidate({ ...validate, [e.target.name]: false });
    }

    

    const changeLocation = (e) => {
        
        if(notEditable) {
            return;
        } 
        setLocation(
            {
                ...location, 
                    
                    pin: {
                        lat: e.lat,
                        lng: e.lng
                    },
            }
        );

        //const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.lat},${e.lng}&key=AIzaSyD1OBKldkqjnDDgsjjyanYIGmALQSCtnzQ`;

        /* fetch(url).then(

            res => res.json()

        ).catch (
            error => console.log(error)
        )
        .then (
            response => console.log(response)
        ) */

    }

    const editStore = () => {
       
        
        if(notEditable) {
            toast.warning(`LA EDICIÓN FUE ACTIVADA, CIERRE LA FILA SI DESEA CANCELAR LA EDICIÓN`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 8000
            })
            setNotEditable(false);
        } else {
            
            const httpHandlerServices = new HttpHandlerServices();
            const url = '/editstore';
            const data = {
                name: dataStore.name,
                id: dataStore.id,
                franchise_id: dataStore.franchise_id,
                zipcode: dataStore.zipcode,
                address: dataStore.address,
                lat: location.pin.lat,
                lng: location.pin.lng,
                state_id: dataStore.state_id,
                locality_id: dataStore.locality_id,
                sublocality: dataStore.sublocality
            }

            httpHandlerServices.postMethod(url, data).then(
                async (res) => {
                    const data = await res;
                    
                    if(data) {
                        toast.warning(`SUCURSAL ACTUALIZADA DE FORMA EXITOSO`
                                , {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 8000
                                });

                        setNotEditable(true);
                        props.reload();

                    }
                },
                (error) => {
                    if(error) {
                        toast.error(`SE PRODUJO UN ERROR AL INTENTAR ACTAULIZAR LA SUCURSAL, POR FAVOR INTENTE MAS TARDE`
                        , {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 8000
                        });
                    }

                }
            )
            
            setNotEditable(true);
        }
    }

    const deleteStore = () => {
       
        const httpHandlerServices = new HttpHandlerServices();
        
        const url = '/deletestore';

        httpHandlerServices.postMethod(url, {id: dataStore.id}).then(
            async (res) => {
                const data = await res;
                if(data) {
                    switch(data.code){
                        case 102:
                            toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                            , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 8000
                            });
                            break;
                        default:
                            toast.warning(`SUCURSAL ELIMINADA DE FORMA EXITOSO`
                            , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 8000
                            });
                            
                    }

                    toggle();
                    props.reload();
                    
                }
            },
            (err) => {
                if(err) {
                    toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                            , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 8000
                            });
                }
            }
        )
    }

    const [franchises, setfranchises] = useState([]);
    const [states, setStates] = useState([]);
    const [localities, setLocalities] = useState([]);

    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedLocality, setSelectedLocality] = useState(null);

    const getFranquises = () => {
        const endpoit = '/showfranchise';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoit).then(
            async res => {
                const data = await res;
                const array = [];
                data.map(item => {
                    const obj = {
                        value: item.id,
                        label: item.franchise
                    }

                    array.push(obj)
                    
                })

                setfranchises(array);

                const item = array.filter(function(obj) {

                    return obj.value === props.data.franchise_id;

                });

                setSelectedFranchise(item);

                getStatesList(props.data.franchise_id)
                
                
            },
            async error => {
                const err =  await error;
                if(err)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }  
        );
    }

    const getStatesList = (franchise_id) => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showstate/${franchise_id}`;
        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
             
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.state,
                            value: ele.id
                        }
                        array.push(item);
                    });

                    setStates(array);
                
                    const item = array.filter(function(obj) {

                        return obj.value === props.data.state_id;
    
                    });
    
                    setSelectedState(item);

                    getLocalityList(props.data.state_id);
                    
                }
                
            }, 
            (error) => {
               if(error)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }
        )
    }

    const getLocalityList = (state_id) => {

        const httpHandlerServices = new HttpHandlerServices();

        const url = `/showlocality/${state_id}`;

        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
                
                if(data){
                    const array = []
                    data.map(ele => {

                        const item = {
                            label: ele.locality,
                            value: ele.id
                        }

                        array.push(item);

                    });

                    setLocalities(array);
                    
                    const item = array.filter(function(obj) {

                        return obj.value === props.data.locality_id;
    
                    });

                    setSelectedLocality(item);

                }
                
            }, 
            (error) => {
                if(error)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }
        )
    }

    const {className} = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [stores, setStores] = useState([]);


    useEffect(()=>{
        getFranquises();
    }, [])

    const MarkComponent = ({ text }) => 
        <div className='pinContainer'>
            <img src={pin} className='pin'/>
        </div>;

    return(
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <h4 className="text-uppercase emigre-bold text-dark text-center">
                        ¿ESTÁS SEGURO DE QUERER DESHABILITAR <br /> LA SUCURSAL?
                    </h4>    
                    <p className="text-center">Selecciona una opción antes de continuar.</p>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={
                            ()=> {
                                deleteStore();
                            }
                        } className="text-uppercase text-white emigre-bold mr-4">ELIMINAR</Button>
                        <Button color="dark" onClick={
                            ()=> {
                                toggle();
                            }
                        } className="text-uppercase text-white emigre-bold">CANCELAR</Button>
                    </div>
                </ModalBody>
            </Modal> 
            
            <Container>
                <Row>
                    <Col md='6' sm='12'>
                        <FormGroup>
                            <Label><i className="icomoon icon-sucursal mr-1"></i> SUCURSAL</Label>
                            <Input 
                            type="text" 
                            name="name" 
                            id="name"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                            value={dataStore.name} 
                            onChange={(e) => getData(e)} invalid={validate.currentPassword} disabled={notEditable}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-shop mr-2"></i> FRANQUICIA</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder='ELIGE UNA FRANQUICIA'
                                name="franchise" 
                                id="franchise"
                                value={selectedFranchise}
                                options={franchises}
                                noOptionsMessage={() => 'Ya no hay más opciones'}
                                onChange={
                                    (e)=>{
                                        setSelectedFranchise(e);
                                        setSelectedState(null);
                                        setSelectedLocality(null);
                                        getStatesList(e.value);
                                        setDataStore({...dataStore, ...dataStore, 
                                            franchise_id: e.value, 
                                            state_id: 0,
                                            locality_id: 0
                                        });
                                    }
                                }
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ff7534',
                                        primary: '#ff7534',
                                        neutral90: 'white',
                                        primary50:"#ff7534",
                                    },
                                    })}
                                isDisabled={notEditable}    
                                />
                            
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-globe mr-2"></i> ESTADO:</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder='ELIGE UNA ESTADO'
                                name="franchise" 
                                id="franchise"
                                value={selectedState}
                                options={states}
                                noOptionsMessage={() => 'Ya no hay más opciones'}
                                onChange={
                                    (e)=>{
                                        setSelectedState(e);
                                        setDataStore({...dataStore, ...dataStore, state_id: e.value})
                                    }
                                }
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ff7534',
                                        primary: '#ff7534',
                                        neutral90: 'white',
                                        primary50:"#ff7534",
                                    },
                                    })}
                                isDisabled={notEditable}    
                                />
                            
                        </FormGroup>

                        <FormGroup>
                        <Label><i className="icomoon icon-globe mr-2"></i> CIUDAD:</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder='ELIGE UNA CIUDAD'
                                name="franchise" 
                                id="franchise"
                                value={selectedLocality}
                                options={localities}
                                noOptionsMessage={() => 'Ya no hay más opciones'}
                                onChange={
                                    (e)=>{
                                        setSelectedLocality(e);
                                        setDataStore({...dataStore, ...dataStore, locality_id: e.value})
                                    }
                                }
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ff7534',
                                        primary: '#ff7534',
                                        neutral90: 'white',
                                        primary50:"#ff7534",
                                    },
                                    })}
                                isDisabled={notEditable}    
                                />
                        </FormGroup>

                        <FormGroup>
                            <Label><i class="material-icons">room</i> LAT:</Label>
                            <Input 
                            type="text" 
                            name="lat" 
                            id="lat"
                            value={location.pin.lat}  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                             
                            disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i class="material-icons">room</i> LNG:</Label>
                            <Input 
                            type="text" 
                            name="lng" 
                            id="lng" 
                            value={location.pin.lng}   
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                             
                            disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label> ZIPCODE:</Label>
                            <Input 
                            type="text" 
                            name="zipcode" 
                            id="zipcode"
                            value={dataStore.zipcode}  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)} 
                            disabled={notEditable}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-globe mr-2"></i>  DIRECCIÓN:</Label>
                            <Input 
                            type="text" 
                            name="address" 
                            id="address"
                            value={dataStore.address}  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            onChange={(e) => getData(e)}
                            disabled={notEditable}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-date mr-2"></i> FECHA DE INGRESO DE SUCURSAL:</Label>
                            <Input 
                            type="text" 
                            name="created_at" 
                            id="created_at"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            value={moment(dataStore.created_at).locale('es').format('L')}
                            disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-date mr-2"></i> FECHA ULTIMO AJUSTE:</Label>
                            <Input 
                            type="text" 
                            name="updated_at" 
                            id="updated_at"  
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            value={
                                moment(dataStore.updated_at).locale("es").format('L')            
                            }
                            disabled={true}/>
                        </FormGroup>

                        <FormGroup>
                            <Label><i className="icomoon icon-user_service mr-1"></i> ULTIMA MODIFICACIÓN REALIZADA POR:</Label>
                            <Input 
                            type="text" 
                            name="update_by" 
                            id="update_by" 
                            value={'admin'} 
                            className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                            disabled={true}/>
                        </FormGroup>
                        
                    </Col>
                    <Col md='6' sm='12' className='mapa_container'>
                                <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyD1OBKldkqjnDDgsjjyanYIGmALQSCtnzQ' }}
                                defaultCenter={location.center}
                                defaultZoom={location.zoom}
                                onClick={(e)=> changeLocation(e)}
                                yesIWantToUseGoogleMapApiInternals
                                >
                                    <MarkComponent
                                        lat={location.pin.lat}
                                        lng={location.pin.lng}
                                        text="Aqui"
                                    />

                                </GoogleMapReact>
                    </Col>
                    <Col lg='6' xs='12'>
                        <Row>
                            <Col xs='6'>
                                <Button 
                                color="primary" 
                                className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" 
                                block
                                onClick={() => editStore()}
                                ><i className="icomoon icon-gear mr-2"
                                
                                ></i>{notEditable ? 'MODIFICAR' : 'GUARDAR'}</Button>
                            </Col>
                           <Col xs='6'>
                                <Button 
                                color="dark" 
                                className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" 
                                block
                                onClick={()=> toggle()}
                                ><i className="material-icons align-bottom">delete</i>ELIMINAR SUCURSAL</Button>
                            </Col>
                        </Row>
                        
                    
                    </Col>

                </Row>
            </Container>
        </Fragment>
    );
}

export default EditStore;