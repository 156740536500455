import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Input, Button, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Layout from '../../layout/layout';
import HttpHandlerServices from '../../../services/httpService';
import { toast } from 'react-toastify';
import dots from '../../../assets/img/dots.gif';
import pizza from '../../../assets/img/makefg.png'
import './Chat.scss';


import { w3cwebsocket as W3CWebSocket } from "websocket";


const Chat = (props) => {
    
    const {className } = props;
    
    const [detail, setDetail] = useState([]);
    
    /* const [conversations_id, setConversations_id] = useState(null); */

    const [conversation, setConversation] = useState([]);

    const [preConversation, setPreConversation] = useState([]);

    const [message, setMessage] = useState([]);

    const [text, setText] =useState("");

    const [modal, setModal] = useState(false);
   
    const toggle = () => setModal(!modal);

    const [loader, setLoader] = useState(false)

    const [userData, setUserData] = useState({
        id: null,
        name: null,
        email: null,
        role: null   
    });

    const [pending, setPending] = useState([])

    const [itsEmpty, setitsEmpty] = useState(false)

    const [client, setClient] = useState(null);

    
    //const ktbots_id = props.match.params._id;

    const ktbots_id = props.match.params.ktbos_id;


    //var client = new W3CWebSocket('ws://littlecaesarswss.ktbo.mx:8090'); 

    //const  client = new W3CWebSocket('wss://wss.littlecaesars.hubcrm.net:443'); 

    //const client = new W3CWebSocket('wss://echo.websocket.org');

    useEffect(()=> {

        //setClient(new W3CWebSocket('ws://littlecaesarswss.ktbo.mx:8090'));

        setClient(new W3CWebSocket('wss://wss.littlecaesars.hubcrm.net:443'));

    },[]);

    useEffect(()=> {

        if(client) {

            getUserData();
    
            getConversation();
    
            handlerSocket();

        }

    },[client]);

   
    
    const closeCoversation = () => {

        client.onclose = function() {
            toast.error(`EL CHAT SE HA DESCONECTADO`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
            });
        };

        setConversation([]);

        const endpoint = `/endconversation/${ktbots_id}`;

        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(
            async (res) => {
                const data = await res;
                if(data) {
                    getConversation();
                }
            },
            async (err) => {
                const data = await err;
                getConversation();
            }
        )
    }


    const getConversation = () => {

        setPending([1])
        
        const endpoint = `/${props.match.params._conversation}/${props.match.params._id}`;

        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async (res) => {
                const data = await res;

                setDetail(data.detail);

                setPreConversation([...data.messages]);

                setPending([]);

                console.log(data.messages)

                if(data.messages.length < 1) {
                    setitsEmpty(true)
                }
                setTimeout(()=>{
                    console.log('scrolled')
                    updateScroll()
                },10)

            
            },
            async (error) => {
                const err = await error;
                if(err) {
                  /*   setLoader(false) */
                    toast.warning(`SE PRODUJO UN ERROR, tal vez acaba de cerrar la conversación`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            }
        );
    }



    const getUserData = () => {

        const endpoint = '/user';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async res => {

                const data = await res;

                setUserData(data);

            },
            error => {

                toast.error(`ERROR AL RECUPERAR DATOS DEL USUARIO, Cierre la session y vuelva a loguearse. Si el error persiste por favor por farvor contacte a soporte help@littlecaesars.com`
                , {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 5000

                });
            }
        );
    }


    const handlerSocket = () => {

        client.onerror = function() {
            toast.error(`ERROR AL INICIAR EL CHAT, Por favor intente ingresar nuevamente`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
            });
        };

        client.onopen = function() {

            console.log('WebSocket Client Connected');
        
            function subscribe() {
                
               if (client.readyState === client.OPEN) {
                
                    client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`)
                    
                }
            }
            
            subscribe();
            
        };
        
        /* client.onclose = function() {
            toast.error(`EL CHAT SE HA DESCONECTADO`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
            });
        }; */
        
        client.onmessage = function(e) {
            
            if (typeof e.data === 'string') {

                updateScroll();
                
                let mesages = message;

                const res = JSON.parse(e.data);
                
                mesages.push(res);
                
                setMessage(message);
                
                setConversation([...mesages]);

                let pendingArray = [...pending]

                pendingArray.splice(0, 1);
               
                setPending(pendingArray);

                console.log('FROM SOCKET:',res);

                if(res.viewed === -1 && res.type === 'user' ) {

                    closeCoversation();

                } 

            } 
        };
    }


    function updateScroll(){

        var element = document.getElementById("chatybox");

        if(element) element.scrollTop = element.scrollHeight;

    }


     const sendMesg = () => {

        setPending(['new', ...pending]);

        let message = text;
        
        let data = JSON.stringify({ "command" : "message", "message": message, "type" : "cms","cms_id" : userData.id});

        if (client.readyState === client.OPEN) {

            console.log('ENVIANDO ABIERTO', data, ktbots_id)

            client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`)

            client.send(data);

        } else {

            client.onopen = () => {

                console.log('ENVIANDO REABRIENDO', data, ktbots_id)

                client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`)

                client.send(data);

            }

        }

        setText('');   

    }

    const closeEmptyCoversation = () => {

        const endpoint = `/emptyconversation/${ktbots_id}`;

        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(
            async (res) => {
                const data = await res;
                if(data) {
                    console.log('res', res)
                    props.history.push(`/atencion`);
                }
            },
            async (err) => {
                const data = await err;
                if(data) props.history.push(`/atencion`);
            }
        )
    }



    return (
        <Layout>

            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>¿REALMENTE DESEA CERRAR ESTA CONVERSACIÓN?</ModalHeader>
                <ModalBody>
                    Si cierra esta conversación no podra volverla a abrir
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={() =>{
                    closeCoversation();
                    toggle();
                } }>Si, Cerrar Conversación</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Container className="chat">
                <Row className="">
                    
                    <Col xs={12}>
                        <Card className="chat_box" color="dark">
                            <Row className="p-4">
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>Categoría</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_type : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-shop mr-2"></i>Sucursal</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail.store ? detail.store.name : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>RAZÓN</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_reason : 'N/A'}</h6>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mb-3">
                            <Row className="p-4">
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-user_service mr-2"></i>Usuario</h6>
                                    <h6 className="text-uppercase text-dark emigre-bold">{detail ? detail.name : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-cel mr-2"></i>Teléfono</h6>
                                    <h6 className="text-uppercase text-dark emigre-bold">{detail ? detail.phone : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-mail mr-2"></i>Correo Electrónico</h6>
                                    <h6 className="text-dark emigre-bold">{detail ? detail.email : 'N/A'}</h6>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Card id='chatybox' className="p-5 chat_msn_box">
                            {/* contenido de la base de datos */}
                            {
                                preConversation.map((ele, i) => (

                                    <Row key={ele.id} className={ ele.viewed === 2 ? 'auto-response-box' : null}>
                                   
                                        {
                                            ele.cms_id === 0  && ele.viewed !== 2 ? 
                                            <Col xs={2} md={1} className="text-left">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col> : null

                                        }

                                        {
                                            ele.cms_id !== 0  && ele.viewed === 2 ? 
                                            <Col xs={2} md={1} className="text-left">
                                              <img class='auto-response-icon' src={pizza} alt='pizza-icon' />
                                            </Col> : null

                                        }

                                        {

                                            ele.image64 === "" || ele.image64 === null || ele.image64 === undefined ? 
                                            <Col xs={10} md={11} className={ele.type === 'user' || ele.cms_id === 0 ? "p-0 text-left" : "p-0 text-right" }>
                                                <p className={"text-dark m-0 emigre chat-text" && ele.type === 'user' || ele.cms_id === 0 ? 'client' : 'operator' }>
                                                    {ele.message}
                                                </p>
                                                <p className="emigre text-secondary"><small>{ele.created_at}</small></p>
                                            </Col>
                                            :
                                            <Col xs={10} md={11} className={ele.type === 'user' || ele.cms_id === 0 ? "p-0 text-left" : "p-0 text-right" }>
                                                <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/>
                                                <p className="emigre text-secondary"><small>{ele.created_at}</small></p>
                                            </Col>

                                        }

                                        {
                                            ele.type === 'user' || ele.cms_id === 0 ? null :
                                            <Col xs={2} md={1} className="text-left">
                                                <i className="chat_icon icomoon icon-atc"></i>
                                            </Col>
                                        }

                                    </Row>

                                ))
                            }

                            {/* mensajes tiempo real */}

                            {
                                conversation.map((ele, i) => (

                                    <Row key={ele.id} className={ele.type === 'user' || ele.cms_id === 0 ? 'user' : 'atc'}>
                                   
                                        {
                                            ele.type === 'user' || ele.cms_id === 0 ? 
                                            <Col xs={2} md={1} className="text-left">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col> :
                                            null
                                        }

                                        {

                                            ele.image64 === "" || ele.image64 === null || ele.image64 === undefined ? 
                                            <Col xs={10} md={11} className={ele.type === 'user' || ele.cms_id === 0 ? "p-0 text-left" : "p-0 text-right" }>
                                                <p className={"text-dark m-0 emigre chat-text" && ele.type === 'user' || ele.cms_id === 0 ? 'client' : 'operator' }>
                                                    {ele.message}
                                                </p>
                                                <p className="emigre text-secondary"><small>{ele.created_at}</small></p>
                                            </Col>
                                            :
                                            <Col xs={10} md={11} className={ele.type === 'user' || ele.cms_id === 0 ? "p-0 text-left" : "p-0 text-right" }>
                                                <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/>
                                                <p className="emigre text-secondary"><small>{ele.created_at}</small></p>
                                            </Col>

                                        }

                                        {
                                            ele.type === 'user' || ele.cms_id === 0 ? null :
                                            <Col xs={2} md={1} className="text-left">
                                                <i className="chat_icon icomoon icon-atc"></i>
                                            </Col>
                                        }

                                    </Row>

                                ))
                            }
                            {
                                pending.map(ele => (
                                    
                                    <div key={ele} className='dots'>
                                        <img src={dots} alt='dots' />
                                    </div>

                                ))

                            } 
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                <Col xs={12}>
                        <Card color="secondary" className="text-white text-center p-2 rounded-0">
                            <Row>
                                <Col xs={12}>
                                    {
                                        detail.status_id === 1 ?
                                        <h5 className="m-0">Sin Contestar</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 2 ?
                                        <h5 className="m-0">Contestando</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 3 ?
                                        <h5 className="m-0">Contestada</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 4 ?
                                        <h5 className="m-0">Sin Contestar Abierta</h5>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Card>
                </Col>
                </Row>
                <Row>
                    {
                        detail.status_id === 3 ? null :
                    
                        <Col xs={12}>
                                <Card className="text-white text-center p-4 m-0 rounded-0">
                                    <Row className="mb-4">
                                        <Col xs={12}>
                                            <Input 
                                            type="text" 
                                            placeholder="Escribe un mensaje aquí" 
                                            className="border-top-0 border-left-0 border-right-0"
                                            id='message'
                                            name='message'
                                            value={text}
                                            onChange={(e)=> {

                                               setText(e.target.value)

                                            }}

                                            onKeyPress ={ (e) => {
                                                if(e.charCode === 13 && text !== '') {
                                                    sendMesg();
                                                }
                                            }}

                                            ></Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="d-flex justify-content-end flex-column flex-md-row">
                                            {
                                                itsEmpty && 
                                                <Button 
                                                    className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2 outline-bt"
                                                    onClick={closeEmptyCoversation}
                                                    >
                                                        Cerrar queja vacía
                                                </Button>
                                            }
                                            <Button 
                                            color="dark" 
                                            className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2"
                                            onClick={toggle}
                                            >
                                                Cerrar aclaración
                                            </Button>
                                            <Button 
                                            color="primary" 
                                            className="rounded-0 px-5 mb-3 text-white text-uppercase emigre-bold order-md-2 order-1"
                                            onClick={sendMesg}
                                            disabled={text === '' ? true : false}
                                            >
                                                Responder
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                        </Col>
                    }
                </Row>
            </Container>
        </Layout>
    );
}

export default Chat;