import React, { Fragment } from 'react';
import Header from '../Header/Header';

const Layout = (props) => {
    
    const {
        children
    } = props;

    return(

        <Fragment>

            <Header />
            {children}
               
        </Fragment>
    );

}

export default Layout;