import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './Panel.scss'
import Filters from '../../Filters/Filters'
import Period from './Period/Period'
import HistoricalTotal from './HistoricalTotal/HistoricalTotal'
import DatePicker from '../../DatePicker/DatePicker'
import Layout from '../../layout/layout'
import { contextApp } from '../../App/contextApp'
import IncidencesByCategory from "./IncidencesByCategory/IncidencesByCategory"
import PercentCategories from './PercentCategories/PercentCategories'
import classnames from 'classnames';
import MaterialTable from 'material-table';
import ComplaintDetail from "./ComplaintDetails/ComplaintDetail";
import HttpHandlerServices from "../../../services/httpService";
import language from './language.json';

const Panel = () =>{
    
    const {       
        sendFilters,
        hasData, 
        currentLanguage
    } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])

    
    const [show, setShow] = useState(true);

    const [activeTab, setActiveTab] = useState('1');

    const [activeTab1, setActiveTab1] = useState(false);

    const [activeTab2, setActiveTab2] = useState(false);

    const [dataFilters, setDataFilter] = useState({

        franchise: '0',
        state: '0',
        city: '0',
        sucursal: '0'

    });

    const [monthsRange, setMonthsRange] = useState('');

    const [dataGeneral, setDataGeneral] = useState([]);

    const [ dataIncidences, setDataIncidences ] = useState([]);

    const [ dataCategoriesResume, setDataCategoriesResume ] = useState([]);

    const [ dataCategories, setDataCategories ] = useState({
        product: 0,
        time: 0,
        service: 0,
        other: 0
    })

    const monthRangeHandler = (range) => {
        setMonthsRange(range);
    }

    const filterHandler = (name, value) => {
        
        if(name === 'franchise') {
            setDataFilter({...dataFilters, franchise: value})
        }

        if(name === 'state') {
            setDataFilter({...dataFilters, state: value})
        }

        if(name === 'city') {
            setDataFilter({...dataFilters, city: value})
        }

        if(name === 'sucursal') {
            setDataFilter({...dataFilters, sucursal: value})
        }
    }
    
    React.useEffect(() => {
        if(monthsRange[0]) getFullData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFilters, monthsRange])
    

    React.useEffect(()=>{
        if(hasData) {
            setShow(true);
        } else {
            setShow(false);
        }
    },[hasData]);

    

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const columns = [
        {field: "name", title: <div><i className="icomoon icon-shop mr-1" /> {texts.franchise}</div>},
        {field: "Cerradas", title: <div><i className="icomoon icon-conversando mr-1" /> {texts.answeredComplaints}</div>}, 
        {field: "Abiertas", title: <div><i className="icomoon icon-sucursal mr-1" /> {texts.unansweredComplaints}</div>},
        {field: "Progress", title: <div><i className="icomoon icon-sucursal mr-1" />Otros</div>},  
        {field: "Total", title: <div><i className="icomoon icon-sucursal mr-1" /> {texts.totalComplaints}</div>}, 
    ];


    const getFullData = () => {
        
        const getDataGeneralEndPoint = `/generalanalysis`;
        const dataGeneral = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }
        
        const getDataCategoriesEndPoint = '/showgeneral/categories';
        const dataCategories = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            months: monthsRange.join()
        }

        
        const getDataResumeIncidencesEndPoint = '/showgeneral/summary';
        const dataIncidencesResume = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            months: monthsRange.join()
        }

        const getDataByCategory = '/showgeneral/summarypay';
        const dataProducto = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            filter: 'calidad_de_producto',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataServicio= {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            filter: 'calidad_de_servicio',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataTiempo = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            filter: 'tiempo_de_entrega',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataOtros = {
            franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
            states: dataFilters.state !== "" ? dataFilters.state : "0",
            cities: dataFilters.city !== "" ? dataFilters.city : "0",
            stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
            filter: 'otros',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const httpHandlerServices = new HttpHandlerServices();



        httpHandlerServices.postMethod(getDataGeneralEndPoint, dataGeneral).then(

            async(res) => {

                const data = await res;

                setDataGeneral(data);

            }
        )

        httpHandlerServices.postMethod(getDataCategoriesEndPoint, dataCategories).then(

            async(res) => {

                const data = await res;
                setDataCategoriesResume(data);
                
            }
            
            )
            
            httpHandlerServices.postMethod(getDataResumeIncidencesEndPoint, dataIncidencesResume).then(
                
                async(res) => {
                    
                    const data = await res;
                    setDataIncidences(data);
                

            }
        )

        httpHandlerServices.postMethod(getDataByCategory, dataProducto).then(
            async(res) => {
                const data = await res;
                
                setDataCategories({...dataCategories, ...dataCategories['product']= data});
                if(data[0]) {
                    if(data[0].total < 1) {
                        //setHasData(false);
                    } else {
                        //setHasData(true)
                    }      
                }
            },(error) => {
                //setHasData(false);
            }
        )

        httpHandlerServices.postMethod(getDataByCategory, dataServicio).then(

            async(res) => {

                const data = await res;

                setDataCategories({...dataCategories, ...dataCategories['service']= data})

            }

        )

        httpHandlerServices.postMethod(getDataByCategory, dataTiempo).then(

            async(res) => {

                const data = await res;

                setDataCategories({...dataCategories, ...dataCategories['time']= data})

            }

        )

        httpHandlerServices.postMethod(getDataByCategory, dataOtros).then(

            async(res) => {

                const data = await res;
                console.log('other', data)
                setDataCategories({...dataCategories, ...dataCategories['other']= data})
                setDataCategories({...dataCategories, ...dataCategories['other']= data})

            }

        )



    }

    
  return (
    <Layout>
       
        <div className="overview">
            <DatePicker sendFilters={sendFilters} monthRangeHandler={monthRangeHandler}/>
            <Filters type={'dashboard'} filterHandler={filterHandler}/>
            { show ? 
                <Container>    
                    <div className='tabs'>
                        <Nav tabs>
                            <NavItem className={activeTab1 ? 'overviewTabs emigre-bold text-secondary activeTabs' : 'overviewTabs emigre-bold text-secondary'}>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' } && 'emigre-bold text-secondary text-center' )}
                                    onClick={() => { 
                                        toggle('1'); 
                                        setActiveTab1(true)
                                        setActiveTab2(false)
                                    
                                    }}
                                >
                                    {texts?.generalViewTab}
                                </NavLink>
                            </NavItem>
                            <NavItem className={activeTab2 ? 'overviewTabs emigre-bold text-secondary activeTabs' : 'overviewTabs emigre-bold text-secondary'}>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' }  && 'emigre-bold text-secondary text-center')}
                                    onClick={() => { 
                                        toggle('2'); 
                                        setActiveTab1(false)
                                        setActiveTab2(true)
                                    }}
                                >
                                    {texts?.franchiseView}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Period dataGeneral={dataGeneral}/>
                                        <HistoricalTotal dataGeneral={dataGeneral} dataCategoriesResume={dataCategoriesResume} dataIncidences={dataIncidences} />
                                        <IncidencesByCategory dataIncidences={dataIncidences} dataCategoriesResume={dataCategoriesResume} dataCategories={dataCategories}/>
                                        <PercentCategories dataCategoriesResume={dataCategoriesResume} dataCategories={dataCategories}/>
                                        <ComplaintDetail dataCategoriesResume={dataCategoriesResume} dataIncidences={dataIncidences} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Container>
                                    <Col sm="6">
                                        <h4 className='emigre-bold text-secondary'>{texts.complainsPerFranchise}</h4>
                                    </Col>
                                    
                                    <Col sm="12">
                    
                                            <MaterialTable 
                                                title=""
                                                columns={columns} 
                                                data={dataGeneral.franchise_list} 
                                                options={{filtering: true, headerStyle: {
                                                    backgroundColor: '#424242',
                                                    color: '#FFF',
                                                    textAlign: 'center',
                                                    fontWeight: 'Bold'
                                                }}}
                                                localization={{
                                                    body: {
                                                        emptyDataSourceMessage: texts.emptyData,
                                                        filterRow: {
                                                            filterTooltip: texts.filterMessage
                                                        }
                                                    },
                                                    toolbar: {
                                                    searchTooltip: texts.findMessage,
                                                    searchPlaceholder: texts.findMessage
                                                    },
                                                    pagination: {
                                                    labelRowsSelect: texts?.rowsMessage,
                                                    labelDisplayedRows: ' {from}-{to} de {count}',
                                                    firstTooltip: texts.toFirst,
                                                    previousTooltip: texts.moveBack,
                                                    nextTooltip: texts.moveNext,
                                                    lastTooltip: texts.toLast
                                                    }
                                                }}
                                            />
                                    </Col>
                                </Container>
                            </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                    
                </Container>
                :
                <Container>
                    <Row className="mb-5">
                        <Col xs="12">
                            <h4 className='emigre-bold text-secondary text-center'>NO HAY RESULTADOS DISPONIBLES</h4>
                        </Col>
                    </Row>    

                </Container>
            }
        </div>
       
    </Layout>)
}

export default Panel;