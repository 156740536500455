import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = ({component: Component, ...rest}) => {

    return (
        <Route 
        {...rest} 
        render= {
            (props => {
            
                const userSession = localStorage.getItem('userSession') ? JSON.parse(localStorage.getItem('userSession')) : {};

                if (userSession.access_token) {
                    return <Component {...props} />
                } else {
                    return <Redirect to = {
                        {
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }
            })
        }/>
    );
}

export default withRouter(ProtectedRoute);