import React, { useEffect, useState } from 'react';
import HttpHandlerServices from '../../../../services/httpService';
import MaterialTable from 'material-table';
import { Modal, ModalBody, Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

import './StoreList.scss';
import EditStore from '../EditStore/EditStore';

const StoreList = (props) => {

    const getStores = () => {

     
            /* const endpoint = '/showstores';
    
            const httpHandlerServices = new HttpHandlerServices();
    
            httpHandlerServices.getMethod(endpoint).then(
                async (res) => {
                    const data = await res;
                    setStores(data);
                },
                async (error) => {
                    const err = await error;
                    if(err)
                    toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                    , {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                } 
            ); */
    

            const endpoint = '/showstoresfull';
    
            const httpHandlerServices = new HttpHandlerServices();

            const data = {
                franchise_ids: 0,
                state_ids: 0,
                locality_ids: 0,
                stores_ids: 0,
                byuser: props.myStores
            }

            httpHandlerServices.postMethod(endpoint, data).then(
                async (res) => {
                    const data = await res;
                    if(data){
                        setStores(data);
                    }
                },
                async (error) => {
                    const err = await error;
                    if(err)
                    toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                    , {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            )

    }

    const [store, setStore] = useState(null);

    const deleteStore = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = '/deletestore';

        httpHandlerServices.postMethod(url, {id:store}).then(
            async (res) => {

                const data = await res;


                if(data) {
                    
                    switch(data.code){
                        case 102:
                            toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                            , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 8000
                            });
                            break;
                        default:
                            toast.warning(`SECURSAL ELIMINADA DE FORMA EXITOSO`
                            , {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 8000
                            });

                            getStores();
                            
                    }
                    
                }

            },
            (err) => {
             
                if(err) {
                    toast.error(`SE PRODUJO UN  ERROR, por favor vuelva a intentarlo`
                        , {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 8000
                        });
                }
            }
        )
        
        toggle();
    
    }

    const {className} = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [stores, setStores] = useState([]);

    const columns = [ 
    
        {title: <div><i className="icomoon icon-shop mr-2" /> SUCURSAL</div>, 
        field: "name",
        render: rowData => <div style={{
            textAlign: 'center',
            }}>
            { rowData.name}
        </div>}, 
        {title: <div> ESTADO</div>, 
        field: "state",
        render: rowData => <div style={{
            textAlign: 'center',
            }}>
            { rowData.state}
        </div>   
        }, 
        {title: <div> CODIGO POSTAL</div>, 
        field: "zipcode",
        render: rowData => <div style={{
            textAlign: 'center',
            }}>
            { rowData.zipcode}
        </div>}, 
        {title: <div> LOCALIDAD</div>, 
        field: "locality",
        render: rowData => <div style={{
            textAlign: 'center',
            }}>
            { rowData.locality}
        </div>
        },

    ] 

    useEffect(()=>{getStores()},[]);

    return(
        <div>
            <ToastContainer />
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <h4 className="text-uppercase emigre-bold text-dark text-center">
                        ¿ESTÁS SEGURO DE QUERER DESHABILITAR <br /> LA SUCURSAL?
                    </h4>    
                    <p className="text-center">Selecciona una opción antes de continuar.</p>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={
                            ()=> {
                                deleteStore();
                            }
                        } className="text-uppercase text-white emigre-bold mr-4">ELIMINAR</Button>
                        <Button color="dark" onClick={
                            ()=> {
                                toggle();
                            }
                        } className="text-uppercase text-white emigre-bold">CANCELAR</Button>
                    </div>
                </ModalBody>
            </Modal>
        
            <MaterialTable 
            title=""
            columns={columns}
            data={stores} 
            localization={{
                body: {
                    emptyDataSourceMessage: 'No hay datos para mostrar',
                    filterRow: {
                        filterTooltip: 'Filtrar'
                    }
                },
                toolbar: {
                    searchTooltip: 'Buscar',
                    searchPlaceholder: 'Buscar'
                },
                pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Principio',
                    previousTooltip: 'Anterior',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Final'
                }
            }}
            actions={[
                rowData => ({
                    icon: 'delete',
                    tooltip: 'Eliminar Sucursal',
                    onClick: (event, rowData) => {
                        setStore(rowData.id);
                        toggle();
                    },
                })
                ]}

            detailPanel={[
            {
                icon: 'arrow_forward_ios_Icon',
                tooltip: 'Detalle',
                render: rowData => {
                    return (
                    <div
                        style={{  }}
                    >
                        <EditStore data={rowData} reload={getStores}/>
                    </div>
                    )
                },
            }
            
            ]}
            
            options={{filtering: false, headerStyle: {
                textAlign: 'center',
                backgroundColor: '#424242',
                color: '#FFF'
                }}}
            />
            
        </div>
    );
}

export default StoreList;