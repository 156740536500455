import React, {useState, useEffect, useContext} from "react"
import { Line } from 'react-chartjs-2'
import { Table, Row, Col } from 'reactstrap';
import { contextApp } from "../../../../App/contextApp"
import language from './language.json';


const IncidencesChart = (props) =>{
    const { monthsLabel, dataIncidences } = props;

    const { Answered, Answering, noAnswered  } = dataIncidences;

    const { currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])


    const getNoAnswered = () => {
        var out = noAnswered;
        if(noAnswered){
            if(noAnswered.length === 1) {
                out = [null, noAnswered[0], null];
            }
        }
        return out;
    }

    const getAnswered = () => {
        var out = Answered;
        if(Answered){
            if(Answered.length === 1) {
                out = [null, Answered[0], null];
            }
        }
        return out;
    }

    const getAnswering = () => {
        var out = Answering;
        if(Answering){
            if(Answering.length === 1) {
                out = [null, Answering[0], null];
            }
        }
        return out;
    }

    const getMonthsLabel = () => {
        var out = monthsLabel;
        if(monthsLabel.length === 1) {
            out = ['nulo', monthsLabel[0], 'nulo' ];
        }
        return out;
    }

    const data = {
        labels: getMonthsLabel(),
        datasets:[{
            label: texts.notAnswered,
            fill: false,
            lineTension: 0,
            backgroundColor: '#ff7534',
            borderColor: '#ff7534',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ff7534',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ff7534',
            pointHoverBorderColor: '#ff7534',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getNoAnswered()
        }, {
            label: texts.answered,
            fill: false,
            lineTension: 0,
            backgroundColor: '#99de14',
            borderColor: '#99de14',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#99de14',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#99de14',
            pointHoverBorderColor: '#99de14',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 20,
            type: 'line',
            data: getAnswered()
        },{
            label: 'Contestando',
            fill: false,
            lineTension: 0,
            backgroundColor: '#29bff0',
            borderColor: '#29bff0',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#29bff0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#29bff0',
            pointHoverBorderColor: '#29bff0',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 20,
            type: 'line',
            data: getAnswering()
        }]
    }

    return( <>
                
                <Row>
                    <Col xs={12}>
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th scope="row" className="bg-secondary text-uppercase">{texts.incidents}</th>
                                    {monthsLabel && monthsLabel.map((item, index) => {
                                        return <th className="text-center bg-secondary" key={index}>{item}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-uppercase">{texts.finished}</td>
                                    {Answered && Answered.map((item, index) => {
                                    return <td className="text-center" key={index}>{item}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td className="text-uppercase">{texts.answered}</td>
                                    {Answering && Answering.map((item, index) => {
                                    return <td className="text-center" key={index}>{item}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td className="text-uppercase">{texts.notAnswered}</td>
                                    {noAnswered && noAnswered.map((item, index) => {
                                    return <td className="text-center" key={index}>{item}</td>
                                    })}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12} md={1} className="d-flex justify-content-center align-items-center">
                        <h5 className="vertical-text emigre-bold text-secondary text-uppercase">{texts.numberOfIncidents}</h5>
                    </Col>
                    <Col xs={12} md={11} className="text-center">
                        <p className="d-inline mx-3">{texts.answered} <span className="legend time-answered"></span></p>
                        <p className="d-inline mx-3">{texts.answering} <span className="legend product-answered"></span></p>
                        <p className="d-inline mx-3">{texts.notAnswered} <span className="legend other-answered"></span></p>
                        
                        <Line data={data} legend={{display:true}}></Line> 
                    </Col>
                </Row>
            </>
        )
}

export default IncidencesChart;