import React, {Fragment, useState, useEffect} from "react";
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import Select from 'react-select';
import HttpHandlerServices from '../../../../services/httpService';
import GoogleMapReact from 'google-map-react';
import pin from '../../../../assets/img/pin.png';
import { toast } from 'react-toastify';
const MarkComponent = ({ text }) => 
<div className='pinContainer'>
    <img alt='pin' src={pin} className='pin'/>
</div>;

const AddNewStore = (props) => {


    const [location, setLocation] = useState({
        center: {
            lat: 19.89,
            lng: -99.33
        },
        pin: {
            lat: '',
            lng: ''
        },
        zoom: 8
    })
    

    const getFranquises = () => {
        const endpoit = '/showfranchise';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoit).then(
            async res => {
                const data = await res;
                const array = [];
                data.map(item => {
                    const obj = {
                        value: item.id,
                        label: item.franchise
                    }

                    array.push(obj)
                    
                })
                setfranchises(array);
                
                
            },
            async error => {
                const err =  await error;
                if(err)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }  
        );
    }

    const [franchises, setfranchises] = useState([]);
    const [states, setStates] = useState([]);
    const [localities, setLocalities] = useState([]);

    useEffect(()=>{
        getFranquises();
    }, [])



    const [storeData, setstoreData] = useState({
        franchise_id : '',
        state_id: '',
        locality_id: '',
        zipcode :'',
        address:'',
        lat: 0,
        lng: 0,
        sublocality: '',
        storeName:'',

    });

    const changeLocation = (e) => {
        
        setLocation(
            {
                ...location, 
                    
                    pin: {
                        lat: e.lat,
                        lng: e.lng
                    },
            }
        );

        setstoreData({...storeData, ...storeData, 
            lat: e.lat, 
            lng: e.lng
        });

       

        //const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.lat},${e.lng}&key=AIzaSyD1OBKldkqjnDDgsjjyanYIGmALQSCtnzQ`;

        /* fetch(url).then(

            res => res.json()

        ).catch (
            error => console.log(error)
        )
        .then (
            response => console.log(response)
        ) */

    }

    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedLocality, setSelectedLocality] = useState(null);



    const onChange = (event) => {
        setstoreData({...storeData, [event.target.id]: event.target.value});
    }

    const getStatesList = (franchise_id) => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showstate/${franchise_id}`;
        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
             
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.state,
                            value: ele.id
                        }
                        array.push(item);
                    });
                    setStates(array);
                }
                
            }, 
            (error) => {
               if(error)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }
        )
    }

    const getLocalityList = (state_id) => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showlocality/${state_id}`;

        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
    
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.locality,
                            value: ele.id
                        }
                        array.push(item);
                    });
                    setLocalities(array);
                }
                
            }, 
            (error) => {
                if(error)
                toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 8000

                });
            }
        )
    }

    const saveNewStore = () => {
        
        const httpHandlerServices = new HttpHandlerServices();

        const url = `/addstore`;
        
        const data = {
            name: storeData.storeName,
            franchise_id: storeData.franchise_id,
            zipcode: storeData.zipcode,
            address: storeData.address,
            lat: `${storeData.lat}`,
            lng: `${storeData.lng}`,
            state_id: storeData.state_id,
            locality_id: storeData.locality_id,
            sublocality: storeData.sublocality

        }
       
        httpHandlerServices.postMethod(url, data).then(
            async(res) => {
                const data = await res;
                if(data) {
                    
                    toast.warning(`SUCURSAL ${storeData.storeName} FUE CREADA CORRECTAMENTE`, {

                        position: toast.POSITION.BOTTOM_RIGHT,
    
                        autoClose: 8000
    
                    });

                    clearData();
                }
            },
            (error) => {

                if(error) {

                    toast.warning(`SE PRODUJO UN ERROR, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {

                        position: toast.POSITION.BOTTOM_RIGHT,
    
                        autoClose: 8000
    
                    });

                }
            }
        )
        
    }

    const clearData = () => {
        setstoreData({
            franchise_id : '',
            state_id: '',
            locality_id: '',
            zipcode :'',
            address:'',
            lat: 0,
            lng: 0,
            sublocality: '',
            storeName:'',
        });
        setSelectedFranchise(null);
        setSelectedState(null);
        setSelectedLocality(null);

        setLocation({...location, pin: {lat: '',lng: ''},});
    }

    const [disabled, setDisabled] = useState(true)

    useEffect(()=>{
        if(storeData.franchise_id !== ''
            && storeData.state_id !== ''
            && storeData.locality_id !== ''
            && storeData.lat !== 0
            && storeData.lng !== 0
            && storeData.storeName !== ''
            && storeData.sublocality !==''
            && storeData.zipcode !== ''
            && storeData.address!== ''
            ){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [storeData])


    return(
        <Fragment>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xs='8' className="mt-5">
                        <h3 className="emigre-bold ">AGREGA TU SUCURSAL</h3>
                        <p className="fontw">RECUERDA QUE TODOS LOS CAMPOS SON OBLIGATORIOS.</p>
                    </Col>
                    <Col xs='8'>
                    <Form>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                   
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder='ELIGE UNA FRANQUICIA'
                                        value={selectedFranchise}
                                        name="franchise" 
                                        id="franchise"
                                        options={franchises}
                                        noOptionsMessage={() => 'Ya no hay más opciones'}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#ff7534',
                                              primary: '#ff7534',
                                              neutral90: 'white',
                                              primary50:"#ff7534",
                                            },
                                          })}
                                        onChange={(e)=> {
                                            setstoreData({...storeData, ...storeData, 
                                                franchise_id: e.value, 
                                                state_id: 0,
                                                locality_id: 0
                                            });
                            
                                            setSelectedFranchise(e);
                                            setSelectedState(null);
                                            setSelectedLocality(null);
                                            getStatesList(e.value);

                                        }}
                                        />
                    
                                    
                                </FormGroup>
                            </Col> 
                       
                            <Col md={4}>
                                <FormGroup>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={selectedState}
                                        placeholder='ELIGE UN ESTADO'
                                        name="state" 
                                        id="state"
                                        options={states}
                                        noOptionsMessage={() => 'DEBE SELECCIONAR UNA FRANQUICIA'}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#ff7534',
                                              primary: '#ff7534',
                                              neutral90: 'white',
                                              primary50:"#ff7534",
                                            },
                                          })}
                                        onChange={(e) => {
                                            setstoreData({...storeData, ...storeData, 
                                                state_id: e.value,
                                                locality_id: 0
                                            })
                                            setSelectedState(e);
                                            setSelectedLocality(null);
                                            getLocalityList(e.value);
                                        
                                        }}
                                        />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>

                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={selectedLocality}
                                        placeholder='ELIGE UNA LOCALIDAD'
                                        name="localities" 
                                        id="localities"
                                        options={localities}
                                        noOptionsMessage={() => 'DEBE SELECCIONAR UN ESTADO'}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#ff7534',
                                              primary: '#ff7534',
                                              neutral90: 'white',
                                              primary50:"#ff7534",
                                            },
                                          })}
                                        onChange={(e) => {
                                            setstoreData({...storeData, ...storeData, locality_id: e.value})
                                            setSelectedLocality(e);
                                        }}
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form className="mb-4">
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="text" 
                                    name="lat" 
                                    id="lat"
                                    value={location.pin.lat} 
                                    placeholder='LATITUD' 
                                    disabled/> 
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="text" 
                                    name="lng" 
                                    id="lng"
                                    value={location.pin.lng}  
                                    placeholder='LONGITUD'
                                    onChange={(e)=>onChange(e)} 
                                    disabled/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form >
                            <Col sm='12' className='mapa_container'>
                               
                                <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyD1OBKldkqjnDDgsjjyanYIGmALQSCtnzQ' }}
                                defaultCenter={location.center}
                                defaultZoom={location.zoom}
                                onClick={(e)=> changeLocation(e)}
                                yesIWantToUseGoogleMapApiInternals
                                //onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
                                >
                                    <MarkComponent
                                        lat={location.pin.lat}
                                        lng={location.pin.lng}
                                        text="Aqui"
                                    />

                                </GoogleMapReact>

                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="text"
                                    value={storeData.storeName} 
                                    name="storeName" 
                                    id="storeName" 
                                    placeholder='NOMBRE' 
                                    onChange={(e)=> onChange(e)}
                                    /> 
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="text"
                                    value={storeData.sublocality}  
                                    name="sublocality" 
                                    id="sublocality" 
                                    placeholder='COLONIA' 
                                    onChange={(e)=> onChange(e)}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="number"
                                    value={storeData.zipcode}  
                                    name="zipcode" 
                                    id="zipcode" 
                                    placeholder='CÓDIGO POSTAL' 
                                    onChange={(e)=> onChange(e)}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input 
                                    className='rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold' 
                                    type="text" 
                                    value={storeData.address}
                                    name="address" 
                                    id="address" 
                                    placeholder='DIRECCIÓN' 
                                    onChange={(e)=> onChange(e)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form >
                            <Col sm="6">
                                <Button size='lg' className={'bg-primary directorios_decarga_buttom emigre-bold mb-5 mt-5'} block
                                    onClick = {
                                        (e) => {
                                            e.preventDefault();
                                            saveNewStore();
                                        }
                                    }
                                disabled={disabled}    
                                >AGREGAR SUCURSAL</Button>
                            </Col>
                            <Col sm="6">
                                
                            </Col>
                        </Row>
                        
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default AddNewStore;