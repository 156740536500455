import React, { useState, useEffect, useContext } from "react"
import {  Row, Col, Card, Badge, CardBody, CardTitle } from 'reactstrap'
import { contextApp } from "../../../../App/contextApp"


import language from '../language.json'

export default function ComplaintsAverage({ dataGeneral }) {


    const { currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])

    const complaintAvg = () => {

        const avg = dataGeneral.complaint / dataGeneral.users;

        return avg.toFixed(2);

    }

    return (
        <Row className="mb-4">
            <Col xs={12}>
                <h4 className="emigre-bold text-secondary text-uppercase">{texts.historyTotal}</h4>
            </Col>
            <Col xs={12} md={4}>
                <Card className="p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase"><i className="icomoon icon-user_service mr-2 "></i>{texts.totalClients}</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-center">{dataGeneral.users ? dataGeneral.users : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.userPerc ? dataGeneral.userPerc : '0%'}</Badge></h3>
                        <p className='text-center text-secondary'>
                            {texts.infoText}
                        </p>
                    </CardBody>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card color='primary' className="text-white p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase"><i className="icomoon icon-conversando mr-2"></i>{texts.totalIncidents}</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-white text-center">{dataGeneral.complaint ? dataGeneral.complaint : 0} <Badge color="dark" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.complaintPerc ? dataGeneral.complaintPerc : '0%'}</Badge></h3>
                        <p className='text-center text-secondary'>
                        {texts.infoText}
                        </p>
                    </CardBody>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase">
                            <i className="icomoon icon-user_service mr-2"></i> 
                            {texts.averageComplaintsPerClient}</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-center">{dataGeneral.users ? complaintAvg() : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.userPerc ? dataGeneral.userPerc : '0%'}</Badge></h3>
                        <p className='text-center text-secondary'>
                        {texts.infoText}
                        </p>
                    </CardBody>
                </Card>
            </Col> 
        </Row> 
    )
}
