import React from 'react';
import loader from '../../assets/img/loader.gif';
import './loader.scss';
const Loader = (props) => {

    return(
        <div className='screenLoader'>
            <img src={loader} alt='loader'/>
        </div>
    )
}


export default Loader;