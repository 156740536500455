import React, {useState, useEffect, useContext} from "react"
import { contextApp } from "../App/contextApp"
import Select from 'react-select';
import HttpHandlerServices from '../../services/httpService';
import { Container, Row, Col, Card } from 'reactstrap'
import './Filters.scss';
import language from './language.json';

const Filters = (props) =>{
    const { type, filterHandler } = props;
    const { 
      selectValue, 

      sendData, 

      setSendData, 

      sendFiltersATC, 

      currentLanguage

    } = useContext(contextApp);

    const [showFilters, setShowFilters] = useState(true);

    const [optionsFran, setOptionsFran] = useState([]);

    const [optionsStates, setOptionsStates] = useState([]);

    const [optionsCities, setOptionsCities]= useState([]);

    const [optionsSuc, setOptionsSuc] = useState([]);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])


    let search = [];

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? '#ff7534'
              : null,
            color: isDisabled
              ? 'white'
              : isSelected,
            cursor: isDisabled ? 'not-allowed' : 'white',
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? 'white' : '#ff7534'),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor:'#ff7534',
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: 'white',
          ':hover': {
            backgroundColor: data.color,
            color: '#dc3545',
          },
        }),
      };

    const methodsFilters = {
        
        getFranchise: (props) => {
            sendData.franchise = '0'
            const httpHandlerServices = new HttpHandlerServices();
            const endpoint = '/showstoresfull';
            const data = {
              franchise_ids: sendData.franchise,
              state_ids: sendData.state,
              locality_ids: sendData.city,
              stores_ids: sendData.sucursal
            }

            httpHandlerServices.postMethod(endpoint, data).then(
                async(res) => {
                    const data = await res;
                    const listFran = [];
                    const listState = [];
                    const listCity = [];
                    const listSuc = [];
                    
                      data.map(item => {
                          const objFran = {
                              value: item.franchise_id,
                              label: item.franchise
                          }
                          listFran.push(objFran)
                          const objState = {
                              value: item.state_id,
                              label: item.state
                          }
                          listState.push(objState)
                          const objCity = {
                              value: item.locality_id,
                              label: item.locality
                          }
                          listCity.push(objCity)
                          const objSuc = {
                              value: item.id,
                              label: item.name
                          }
                          listSuc.push(objSuc)
                      })
                    
                    const sortListFran = listFran.map(e => e['value']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => listFran[e]).map(e => listFran[e]);
                    const sortListStates = listState.map(e => e['label']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => listState[e]).map(e => listState[e]);
                    const sortListCity= listCity.map(e => e['label']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => listCity[e]).map(e => listCity[e]);
                    const sortListSuc= listSuc.map(e => e['label']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => listSuc[e]).map(e => listSuc[e]);
                    setOptionsFran(sortListFran.sort(function(a, b){
                      if(a.label < b.label) { return -1; }
                      if(a.label > b.label) { return 1; }
                      return 0;
                    }));
                    setOptionsStates(sortListStates.sort(function(a, b){
                      if(a.label < b.label) { return -1; }
                      if(a.label > b.label) { return 1; }
                      return 0;
                    }));
                    setOptionsCities(sortListCity.sort(function(a, b){
                      if(a.label < b.label) { return -1; }
                      if(a.label > b.label) { return 1; }
                      return 0;
                    }));
                    setOptionsSuc(sortListSuc.sort(function(a, b){
                      if(a.label < b.label) { return -1; }
                      if(a.label > b.label) { return 1; }
                      return 0;
                    }));
                },(error) => {
                  console.log(error);
                }
            )
        },
        handleChange: (event, name) => {
          search = [];
          if(event !== null){
            
              event.map(item => search.push(item.value));
              
              setSendData({...sendData, ...sendData[name]= search.join()})
              
              filterHandler(name, search.join());
              
            }else{
            
              setSendData({...sendData,...sendData[name] = '0'})
              
              filterHandler(name, search.join());

            }

          methodsFilters.sendFilters();
        },
        sendFilters: () => {
         
          if(type === 'dashboard'){
            //sendFilters(selectValue.months, selectValue.monthsRange);
          }else if(type === 'atc-table'){
            sendFiltersATC();
          }
        },
    }

    useEffect(() =>{
      methodsFilters.getFranchise();
      if(type === 'dashboard'){
        //methodsFilters.sendFilters();
      }else if(type === 'atc-table'){
        methodsFilters.sendFilters();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return(
        <Container tag="section" className="filters mb-5">
            <Card body tag="article" className="d-block z-indez-99">
                <Row xs={12}>
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={texts.filterByFranchise}
                            name="franchise" 
                            id="franchise"
                            closeMenuOnSelect={true}
                            isMulti
                            options={optionsFran}
                            noOptionsMessage={() => texts.noMoreData}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=> {

                              methodsFilters.handleChange(e, 'franchise')
                              
                            }}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={texts.filterByState}
                            closeMenuOnSelect={true}
                            name="state" 
                            id="state"
                            isMulti
                            options={optionsStates}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=> methodsFilters.handleChange(e, 'state')}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="city" 
                            id="city"
                            placeholder={texts.filterByFranchise}
                            closeMenuOnSelect={true}
                            isMulti
                            noOptionsMessage={() => texts.noMoreData}
                            options={optionsCities}
                            styles={colourStyles}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#ff7534',
                                primary: '#ff7534',
                                neutral90: 'white',
                                primary50:"#ff7534",
                              },
                            })}
                            onChange={(e)=> methodsFilters.handleChange(e, 'city')}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="sucursal" 
                                id="sucursal"
                                placeholder={texts.filterByBranch}
                                closeMenuOnSelect={true}
                                isMulti
                                options={optionsSuc}
                                styles={colourStyles}
                                theme={theme => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ff7534',
                                    primary: '#ff7534',
                                    neutral90: 'white',
                                    primary50:"#ff7534",
                                  },
                                })}
                                onChange={(e)=> methodsFilters.handleChange(e, 'sucursal')}
                                />
                    </Col>
                </Row>
            {selectValue.franchise !== '' && 
                <Row>
                    <Col xs={12}>
                        <h6 className="text-secondary mt-2 mb-3emigre-bold text-uppercase show-filters" align="center" onClick={() => setShowFilters(!showFilters)}>{showFilters ? 'Ocultar filtros':'mostrar Filtros'}</h6>
                    </Col>
                </Row>
            }
            </Card>
        </Container>
        )
    }

export default Filters