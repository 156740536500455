import React, { useContext, useState, useEffect } from "react"
import { Container, Row, Col, Card, Badge, CardBody, CardTitle } from 'reactstrap'
import { contextApp } from "../../../App/contextApp"
//import HistoricalChart from "./HistoricalChart/HistoricalChart";
import TimeAverage from './TimeAverage/TimeAverage';
import TimeClosingAvg from "./timeclosingAvg";

import language from './language.json';
import ComplaintsAverage from "./ComplaintsAverage/ComplaintsAverage";
import ComplaintsAtBranch from "./ComplaintsAtBranch/ComplaintsAtBranch";
import ComplaintsThridParty from "./ComplaintsThridParty/ComplaintsThridParty";


const HistoricalTotal = (props) =>{
    
    const { selectValue, currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])
   
    const { dataCategoriesResume, dataGeneral } = props;
    const { avg_close, avg_time } = dataCategoriesResume;
    const { monthsLabel } = selectValue;

    const complaintAvg = () => {

        const avg = dataGeneral.complaint / dataGeneral.users;

        return avg.toFixed(2);

    }

    return(
            <Container tag="section" className="historical">
                <Row tag="article">
                    <Col xs={12}>
        
                        <ComplaintsAverage dataGeneral={dataGeneral}/>
                        <ComplaintsAtBranch dataGeneral={dataGeneral}/>
                        <ComplaintsThridParty dataGeneral={dataGeneral}/>

                        <TimeAverage 
                            avg_close={ dataCategoriesResume.avg_close } 
                            avg_time={ dataCategoriesResume.avg_time } 
                            avgTimeAnswer={texts.avgTimeAnswer}
                            avgCloseTime={texts.avgCloseTime}
                        />
                        <Row className='mb-4'>
                            <Col xs={12}>
                            <Card className="p-3 text-center">
                                <div>
                                    <p className="d-inline mx-3 emigre-bold text-secondary">{texts.avgCloseTime}<span className="legend time-answered"></span></p>
                                    <p className="d-inline mx-3 emigre-bold text-secondary">{texts.avgTimeAnswer} <span className="legend other-answered"></span></p>
                                </div>
                                <TimeClosingAvg 
                                    monthsLabel={monthsLabel} 
                                    dataCategoriesResume={dataCategoriesResume}
                                    label1={texts.label1}
                                    label2={texts.label2} 
                                
                                />                               
                                {/* <HistoricalChart monthsLabel={monthsLabel} dataIncidences={dataIncidences}/> */}
                            </Card>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
            </Container> 
        )
    }

export default HistoricalTotal